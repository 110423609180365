<template>
    <div class="declare">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>筛选字段</span>
                    <el-select
                        v-model="field"
                        size="small"
                        style="width:100px"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option label="合同名称" value="1"></el-option>
                        <el-option label="甲方" value="2"></el-option>
                        <el-option label="打款人" value="3"></el-option>
                        <el-option label="备注" value="4"></el-option>
                    </el-select>

                    <el-input
                        size="small"
                        style="width:150px;margin-left:16px"
                        placeholder="请输入内容"
                        v-model="fieldVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span>创建时间</span>
                    <el-radio-group v-model="createTime" size="small">
                        <el-radio-button
                            label="3天内"
                            @click.native.prevent="createitem('3天内')"
                        ></el-radio-button>
                        <el-radio-button
                            label="10天内"
                            @click.native.prevent="createitem('10天内')"
                        ></el-radio-button>
                        <el-radio-button
                            label="30天内"
                            @click.native.prevent="createitem('30天内')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        size="small"
                        v-model="createDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:240px;margin-left:19px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="margin-right:16px; border-color: #2370EB;background:#2370EB"
                        size="small"
                        @click="getTableData"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="margin:0;background:none;border-color:#D9D9D9;color:#333333"
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                    <el-button
                        v-show="fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right:29px;color:#2162CB"
                    >
                        收起 <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button
                        v-show="!fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right:29px;color:#2162CB"
                    >
                        展开 <i class="el-icon-arrow-down"></i>
                    </el-button>
                </p>
            </div>
            <div
                class="search-two"
                v-show="fold && (btnP.searchUser || btnP.depSearch)"
            >
                <span v-show="btnP.searchUser">成员</span>
                <el-select
                    v-show="btnP.searchUser"
                    v-model="sourceAdminIdVal"
                    placeholder="请选择"
                    size="small"
                    style="width:180px"
                    clearable
                    @change="getTableData()"
                >
                    <el-option
                        v-for="item in userName"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <span v-show="btnP.depSearch">部门</span>
                <el-select
                    v-model="dempVal"
                    size="small"
                    v-show="btnP.depSearch"
                    style="width:170px;"
                    clearable
                    placeholder="请选择"
                    @change="getTableData()"
                >
                    <el-option
                        v-for="item in dempData"
                        :key="item.id"
                        :label="item.departmentName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="table-title">
            <div style="display:flex">
                <p>
                    申报总金额
                    <span>
                        {{ statistics.declareTotalAmount }}（{{ total }}单）
                    </span>
                </p>
                <p style="margin-left:64px">
                    到款总金额{{ statistics.paymentTotalAmount }}（{{
                        total
                    }}单）
                </p>
            </div>

            <span>
                <!-- <button class="add" @click="onAddDeclare">
                    <i class="el-icon-plus"></i> 新建申报
                </button> -->
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>
            <el-table-column
                label="合同标题"
                prop="contractTitle"
                width="150"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="甲方" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color:#2370EB"
                        @click="onDetails(scope.row)"
                        >{{ scope.row.contractA }}</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column
                label="合同金额"
                prop="declareAmount"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="乙方" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span class="text" v-if="scope.row.typeB != 9">
                        {{ $tableDataHandle.typeB(scope.row.typeB) }}
                    </span>
                    <span class="text" v-if="scope.row.typeB == 9">{{
                        scope.row.contractB
                    }}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="审核状态"
                prop="status"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '审核中'
                            : scope.row.status == 2
                            ? '已驳回'
                            : scope.row.status == 3
                            ? '已审核'
                            : scope.row.status == 0
                            ? '未审核'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="到款状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.paymentStatus == 1
                            ? '未到款'
                            : scope.row.paymentStatus == 2
                            ? '部分到款'
                            : scope.row.paymentStatus == 3
                            ? '全部到款'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="到款金额（元）" min-width="110">
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top-start"
                        v-if="scope.row.contractDeclarePaymentList.length > 0"
                    >
                        <div
                            slot="content"
                            v-for="item in scope.row.contractDeclarePaymentList"
                        >
                            <p style="padding:5px 0">
                                {{ item.createTime }} 到款
                                {{ item.paymentAmount }}元
                            </p>
                        </div>
                        <p>
                            <span>{{ scope.row.paymentAmount }}</span>
                            <span
                                class="Times"
                                style="margin-left:5px"
                                v-if="
                                    scope.row.contractDeclarePaymentList
                                        .length > 0
                                "
                                >{{
                                    scope.row.contractDeclarePaymentList.length
                                }}</span
                            >
                        </p>
                    </el-tooltip>
                    <span
                        v-if="scope.row.contractDeclarePaymentList.length == 0"
                        >{{ scope.row.paymentAmount }}</span
                    >
                </template>
            </el-table-column>

            <el-table-column
                label="申报时间"
                prop="createTime"
                show-overflow-tooltip
            >
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display:block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>

        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawers"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    申报详情
                </div>
            </template>
            <DeclareDetail @close="handleClose" ref="details" />
        </el-drawer>
        <!--  立即申报-->
        <el-dialog
            :visible.sync="drawerDeclare"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    确认申报
                </div>
            </template>
            <p class="operationText">
                是否立即申报？
            </p>
            <div class="bottom-but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="background:none;border-color:#D5D6DF;color:#666666;"
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    @click="onSubmit"
                    type="primary"
                    style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:25px"
                    size="mini"
                    >确认</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { roleList } from '@/api/user/clue';
import DeclareDetail from '../details/details.vue';
import { statisticsMy } from '@/api/user/ae/declare.js';
import { contractList } from '@/api/user/ae/contract.js';
import { declareCommit } from '@/api/user/ae/declare';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    components: {
        DeclareDetail,
    },
    data() {
        return {
            statistics: {
                auditedTotalAmount: 0,
                auditedCount: 0,
                auditingTotalAmount: 0,
                auditingCount: 0,
            },
            fold: true,
            field: '',
            fieldVal: '',
            createTime: '',
            createDate: [],
            status: '',
            userName: [],
            sourceAdminIdVal: '',
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawer: false,
            drawers: false,
            direction: 'rtl',
            drawerDeclare: false,
            row: {},
            btnP: {},
            dempData: [],
            dempVal: '',
        };
    },
    mounted() {
        this.createDate = this.$searchTime.thisMonth();
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            // console.log(btn);
            if (btn.searchUser) {
                this.getUser();
            }
            if (btn.depSearch) {
                this.getDempData();
            }
            this.getTableData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
            });
        },
        getUser() {
            let data = {
                param: {
                    departmentId: sessionStorage.getItem('departmentId'),
                    roleTypeList: [4, 8, 9, 10, 17],
                },
            };
            roleList(data).then((res) => {
                this.userName = res.data;
                this.sourceAdminIdVal = Number(
                    sessionStorage.getItem('adminId')
                );
                this.getTableData();
            });
        },
        getStatistics(data) {
            statisticsMy(data).then((res) => {
                if (res.data) {
                    this.statistics = res.data;
                } else {
                    this.statistics = [];
                }
            });
        },
        getTableData() {
            let data = {
                param: {
                    paymentStatus: 2,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.field != '' && this.fieldVal == '') {
                return this.$message.error('请输入查询字段内容');
            } else if (this.field == '' && this.fieldVal != '') {
                return this.$message.error('请选择查询字段');
            } else if (this.field == 1) {
                data.param.contractTitle = this.fieldVal;
            } else if (this.field == 2) {
                data.param.contractA = this.fieldVal;
            } else if (this.field == 3) {
                data.param.payContract = this.fieldVal;
            } else if (this.field == 4) {
                data.param.remark = this.fieldVal;
            }
            if (this.createTime != '') {
                let date = new Date();
                this.createDate = [];
                data.param.endCreateDate = this.$searchTime.getNextDate(
                    date,
                    0
                );
                if (this.createTime == '3天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        3
                    );
                } else if (this.createTime == '10天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        10
                    );
                } else if (this.createTime == '30天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        30
                    );
                }
            }
            if (this.createDate && this.createDate.length > 0) {
                data.param.startCreateDate = this.createDate[0];
                data.param.endCreateDate = this.createDate[1];
            }
            if (this.btnP.searchUser) {
                data.param.departmentId = sessionStorage.getItem(
                    'departmentId'
                );
            } else {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (this.sourceAdminIdVal != '') {
                data.param.adminId = this.sourceAdminIdVal;
            }
            if (this.dempVal != '') {
                data.param.departmentId = this.dempVal;
            }
            contractList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.getStatistics(data);
            });
        },
        // 创建时间点击
        createitem(e) {
            e === this.createTime
                ? (this.createTime = '')
                : (this.createTime = e);

            this.getTableData();
        },
        clickStatus(e) {
            e === this.status ? (this.status = '') : (this.status = e);
            this.getTableData();
        },
        // 时间输入框变化
        expireTime() {
            if (this.createDate && this.createDate.length > 0) {
                this.createTime = '';
            }
            this.getTableData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        // 编辑
        onEdit(row) {
            this.$router.push({
                path: '/aearchiving',
                query: {
                    type: 'edit',
                },
            });
            this.$formDetails.commit('detailsData', JSON.stringify(row));
        },
        // 详情
        onDetails(row) {
            this.drawers = true;
            setTimeout(() => {
                this.$refs.details.getData();
            }, 50);
            this.$formDetails.commit('detailsData', JSON.stringify(row));
        },
        // 立即申报
        onDeclare(row) {
            this.drawerDeclare = true;
            this.row = row;
        },
        onSubmit() {
            // console.log(this.row);
            let data = {
                param: {
                    id: this.row.id,
                },
            };
            declareCommit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                }
                this.handleClose();
            });
        },
        handleClose() {
            this.drawer = false;
            this.drawers = false;
            this.drawerDeclare = false;
            this.getTableData();
        },
        searchEmpty() {
            this.field = '';
            this.fieldVal = '';
            this.status = '';
            this.createTime = '';
            this.createDate = [];
            this.getTableData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.declare {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .operationText {
        text-align: center;
        padding: 50px 0 100px 0;
        font-size: 16px;
        color: #333;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;
        button {
            width: 90px;
        }
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
}
</style>
